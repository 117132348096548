<template>
  <div class="menu">
    <h5 class="d-flex px-2">
      {{ menu.name }}
      <b-badge
        v-if="menu.price"
        pill
        variant="dark"
        class="py-2 ml-auto btext-nowrap"
      >
        {{ menu.fullPrice }}
      </b-badge>
    </h5>
    <b-row>
      <b-col cols="12" lg="6">
        <ul class="px-2">
          <li v-for="dish in menu.dishes" :key="dish.id" class="dish">
            <h6 v-if="dish.courses.length" class="text-uppercase mt-3 mb-0">
              {{ dish.courses[0].name }}
            </h6>
            <span v-html="matchedDish(dish.name)"></span>
          </li>
        </ul>
      </b-col>
      <b-col cols="12">
        <div class="media-wrapper">
          <my-carousel
            :media="menu.mediaObject"
            @onImageClick="(media, i) => setFullScreenMedia(media, i)"
          >
          </my-carousel>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { reservationHighlight } from "@/utils"
import MyCarousel from "@/components/MyCarousel"
export default {
  components: {
    MyCarousel
  },
  props: {
    menu: {
      type: Object,
      required: true
    },
    search: {
      type: String,
      default: null
    }
  },
  methods: {
    setFullScreenMedia(media, index) {
      this.$store.commit("fullScreenMedia", { media, index })
    },
    matchedDish(dish) {
      return reservationHighlight(this.search, dish)
    }
  }
}
</script>

<style lang="scss" scoped>
.dish {
  white-space: pre-wrap;
}
.media-wrapper {
  li {
    height: 250px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
</style>
