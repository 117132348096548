<template>
  <b-card class="permanent-menu">
    <template #header>
      <h6 v-html="matchedDish(menu.name)"></h6>
    </template>
    <div class="d-flex justify-content-end">
      <p v-if="menu.price > 0" class="price rounded-pill px-3 py-1">
        {{ menu.fullPrice }}
      </p>
    </div>
    <div v-html="matchedDish(menu.dishes[0].name)"></div>
  </b-card>
</template>

<script>
import { reservationHighlight } from "@/utils"
export default {
  props: {
    menu: {
      type: Object,
      required: true
    },
    search: {
      type: String,
      default: ""
    }
  },
  methods: {
    matchedDish(dish) {
      return reservationHighlight(this.search, dish)
    }
  }
}
</script>

<style lang="scss">
.permanent-menu ul {
  list-style: disc;
}
</style>
