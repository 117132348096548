<template>
  <div>
    <div class="my-carousel">
      <div class="navs">
        <div class="left" @click="prev">
          <button>
            <font-awesome-icon icon="angle-left" sise="lg" />
          </button>
        </div>
        <div class="right" @click="next">
          <button>
            <font-awesome-icon icon="angle-right" sise="lg" />
          </button>
        </div>
      </div>
      <div class="slides" v-touch:swipe.left="next" v-touch:swipe.right="prev">
        <div v-for="(m, i) in media" :key="m.id" class="slide">
          <img
            :src="m.thumbs"
            draggable="false"
            @click="$emit('onImageClick', media, i)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MyCarousel",
  props: ["media"],

  data() {
    return {
      index: 0,
      max: 0,
      maxX: 0,
      isMax: false
    }
  },
  mounted() {
    const imgs = this.$el.querySelectorAll("img")
    const len = imgs.length
    let counter = 0
    const incrementCounter = () => {
      counter++
      if (counter === len) {
        this.init()
        window.addEventListener("resize", this.init)
      }
    }

    imgs.forEach(img => {
      if (img.complete) incrementCounter()
      else img.addEventListener("load", incrementCounter, false)
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.init)
  },
  methods: {
    init() {
      this.index = 0
      this.$el.querySelectorAll(".my-carousel .slide").forEach(s => {
        s.dataset.left = s.offsetLeft
      })
      const last = this.$el.querySelector(".my-carousel .slide:last-child")
      this.maxX =
        last.offsetLeft +
        last.getBoundingClientRect().width -
        this.$el.querySelector(".my-carousel .slides").getBoundingClientRect()
          .width
      this.$el.querySelector(
        `.my-carousel .slides`
      ).style.transform = `translateX(0px)`
    },
    prev() {
      this.isMax = false
      this.index = Math.max(this.index - 1, 0)
      this.goto(this.index)
    },
    next() {
      if (!this.isMax) {
        this.index += 1
      }
      const next = this.$el.querySelector(
        `.my-carousel .slide:nth-child(${this.index + 1})`
      )

      if (next.dataset.left > this.maxX) {
        this.isMax = true
      }

      this.goto(this.index)
    },
    goto(index) {
      const elt = this.$el.querySelector(
        `.my-carousel .slide:nth-child(${index + 1})`
      )
      const left = Math.min(elt.dataset.left, this.maxX)
      this.$el.querySelector(
        `.my-carousel .slides`
      ).style.transform = `translateX(-${left}px)`
    }
  }
}
</script>

<style lang="scss" scoped>
.my-carousel {
  overflow: hidden;
  position: relative;
  margin: 10px 0;
  .navs {
    position: absolute;
    pointer-events: none;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1000;
    .left,
    .right {
      pointer-events: all;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        outline: none;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background-color: #314a72;
        color: white;
      }
    }

    .right {
      right: 0;
      left: auto;
    }
  }
  .slides {
    display: flex;
    flex-direction: row;
    transition: all 0.4s ease-in;
    .slide {
      padding: 0 5px;
    }
    .slide {
      img {
        height: 150px;
      }
    }
  }
}
</style>
