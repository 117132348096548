<template>
  <div
    v-advertiser="{ campaignId: advertiser.campaignId, view: view }"
    class="p-2 p-md-4 active-deal text-white position-relative"
    :style="'background-image: url(' + deal.background + ')'"
  >
    <div class="position-absolute logo-wrapper">
      <b-img :src="advertiser.logo.original" height="50" />
    </div>
    <div class="d-flex justify-content-between position-relative">
      <a
        v-advertiser-click="{ campaignId: advertiser.campaignId, view: view }"
        class="h-100 position-absolute icon-wrapper d-flex align-items-center"
        :href="advertiser.link"
        target="_blank"
      >
        <b-img :src="deal.icon" />
      </a>
      <a
        v-advertiser-click="{ campaignId: advertiser.campaignId, view: view }"
        class="active-deal__content col px-1 advertiser"
        :href="advertiser.link"
        target="_blank"
      >
        <h4 class="text-uppercase">Bon plan du jour !</h4>
        <p>{{ advertiser.detailVenueText }}</p>
      </a>
      <div class="text-white d-flex align-items-center">
        <h4 class="price mb-0">{{ deal.price }} €</h4>
      </div>
    </div>
    <b-btn
      class="rounded-pill"
      variant="white"
      style="white-space: normal"
      @click="$emit('click')"
    >
      Débloquer mon bon Plan
    </b-btn>
  </div>
</template>

<script>
import autoAction from "@/mixins/autoAction";
export default {
  mixins: [autoAction],
  props: {
    advertiser: {
      type: Object,
      required: true,
    },
    deal: {
      type: Object,
      required: true,
    },
    view: {
      type: String,
      default: "list",
    },
  },
  mounted() {},
  methods: {
    doAction() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.active-deal {
  .logo-wrapper {
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  a {
    color: white;
  }
  a:hover {
    color: white;
    text-decoration: none;
  }
  background-size: cover;
  .icon-wrapper {
    margin-left: -50px;
  }
  &__content,
  .btn {
    margin-left: 60px;
  }
}
</style>
