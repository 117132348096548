<template>
  <div class="p-3 d-flex flex-column flex-md-row publication">
    <div class="mb-2 mb-md-0 text-center">
      <b-img
        v-if="publication.picture"
        slot="aside"
        :src="publication.picture.thumbs"
        width="200"
        alt="placeholder"
        class="mr-md-3"
        @click.prevent="setFullScreenMedia([publication.picture], 0)"
      />
    </div>
    <div>
      <h4>{{ publication.name }}</h4>
      <div class="publication__content" v-html="publication.message"></div>
      <h6 class="publication__footer">
        Actualité publiée le : {{ publication.publishedAt.localeFormat }}
      </h6>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    publication: {
      type: Object,
      required: true
    }
  },
  methods: {
    setFullScreenMedia(media, index) {
      this.$store.commit("fullScreenMedia", { media, index })
    }
  }
}
</script>

<style lang="scss" scoped>
.publication {
  img {
    cursor: pointer;
  }
  .publication__content {
    font-size: 18px;
  }
  .publication__footer {
    margin-top: 1.5em;
    font-size: 14px;
  }
}
</style>
